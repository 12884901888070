.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Alinhado ao topo */
  height: 100vh;
  position: relative; /* Add relative positioning */
  padding-top: 20vh; /* Adjust this value to control how far down from the top the loader should be */
}

.loader {
  margin-top: 0; /* Remove the margin-top if you want to control spacing using padding-top on .loader-container */
  position: relative; /* Change from absolute to relative */
  width: 6.250em;
  height: 6.250em;
  animation: rotate5123 2.4s linear infinite;
}

.progress-wrapper {
  width: 80%;
  padding: 20px;
  position: relative; /* Change this to relative */
  margin-top: 20px; /* Adjust space between loader and progress bar */
}

.progress-bar {
  background-color: #f3f4f6;
  border-radius: 10px;
  overflow: hidden;
}

.progress-fill {
  background: linear-gradient(90deg, #4ade80 0%, #22c55e 100%);
  height: 20px;
  border-radius: 10px;
}

.timer-seconds {
  text-align: center;
  font-size: 16px;
  color: #374151;
  margin-top: 10px;
}

.icon-and-text {
  text-align: center;
  position: absolute;
  top: 60%; /* Posição após o progresso */
  left: 50%;
  transform: translate(-50%, -50%);
}

.save-button-unique {
  background-color: #0F7838; /* Darker green */
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.save-button-unique:hover {
  background-color: #0C6030; /* Even darker on hover */
}

.centered-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.white {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}

.dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}

.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #FF4444;
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #FFBB33;
  animation: dotsX 2.4s linear infinite;
}

.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #99CC00;
  animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #33B5E5;
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate5123 {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes dotsY {
  66% {
    opacity: .1;
    width: 2.4em;
  }
  77% {
    opacity: 1;
    width: 0;
  }
}

@keyframes dotsX {
  66% {
    opacity: .1;
    height: 2.4em;
  }
  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33%, 66% { opacity: 0; }
  55% { opacity: .6; }
}
