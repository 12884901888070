
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
  }
  
  .signup-card {
    width: 90%;
    max-width: 660px; 
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    text-align: center;
  }

  .form-checkbox{
    transform: scale(1.2);
  }
  

  .logo-image {
    width: 110px;
    height: 110px;
    display: block;
    margin: 0 auto -10px;
  }
  
  .signup-heading {
    font-size: 1.5rem;
    color: #333;
    font-family: 'Poppins', sans-serif; /* Adiciona a fonte Poppins */
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold; /* Adiciona negrito */
}

  
  .signup-form {
    margin-bottom: 10px;
   
  }
  
  .form-group {
    margin-bottom: 5px;
    
  }
  

  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 1.1rem;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    color: #555; /* Adjusted color */
}

.form-input::placeholder {
    color: #727272; /* Cor do placeholder */
}

  .error-message {
    color: #ff0000;
    margin-top: 8px;
    margin-bottom: 12px;
    text-align: center;
  }
  
  .signup-button {
    width: 100%;
    padding: 12px;
    background-color: #48be03;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  
  .signup-links {
    text-align: center;
    font-weight: bold;
  }
  
  .signin-link {
    color: #585858;
    text-decoration: none;
    margin-top: 2px;
    display: inline-block;
    font-size: 0.875rem;
    padding: 8px 12px;
    border: 2px solid #585858;
    border-radius: 25px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }


  @media (max-width: 760px) {
    
  .form-input {
    padding: 15px;
  }
  .signup-form {
    margin-bottom: 15px;
   
  }
  .signup-heading {
    margin-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 10px;
    
  }
  }
  

  @media (max-width: 376px) { /* Exemplo de max-width para telas menores, como iPhone SE */
    .signup-container {
      height: 100vh; /* Mantendo a altura total da tela */
      display: flex;
      justify-content: center;
      align-items: center; /* Centralizando verticalmente o conteúdo */
    }
  
    .signup-card {
      width: 85%;
      max-width: 90%; /* Reduzindo a largura máxima do card */
      padding: 15px; /* Reduzindo o padding do card */
    }
    
    .logo-image {
      width: 80px; /* Reduzindo o tamanho da logo */
      height: 80px; /* Reduzindo o tamanho da logo */
      margin-bottom: 10px; /* Ajustando a margem inferior da logo */
    }
  }
  