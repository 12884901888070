/* ListaCompras.css */
.listaCompras-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .listaCompras-container h1, .listaCompras-container h2 {
    text-align: center;
    color: #333;
  }
  
  .textarea-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .textarea-container textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .button-container {
    text-align: center;
  }
  
  .button-container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-container button:hover {
    background-color: #45a049;
  }
  
  .listaCompras-cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 10px;
    padding: 20px;
  }
  
  .listaCompras-card {
    background: white;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  /* React Component Styling and Structure */
  